<template>
  <section class="section subsequent">
    <div class="container narrow">
      <div class="columns">
        <div class="column" :class="hasImage">
          <div class="columns">
            <div class="column text-column">
              <h1 v-if="title" class="title">
                {{ title }}
              </h1>
              <div class="content" v-html="text"/>
              <div v-if="ctaLink" class="buttons is-centered mt-6">
                <VuepalLink :to="ctaLink?.uri?.path" class="button is-primary"
                  >{{ ctaLink.title }}
                </VuepalLink>
              </div>
            </div>
          </div>
        </div>
        <div v-if="media" class="column">
          <div class="image-wrapper">
            <MediaImage
              v-bind="media"
              :image-style="textImage"
              class="image"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import type { ParagraphImageTextFragment } from '#graphql-operations'

const props = defineProps<{
  title?: string
  text?: string
  ctaLink?: ParagraphImageTextFragment['ctaLink']
  media?: ParagraphImageTextFragment['media']
}>()

const textImage = defineImageStyle({
  type: 'sizes',
  aspectRatio: 270 / 120,
  sizes: {
    xs: 270,
    sm: 270,
    md: 366,
    lg: 424,
    xl: 445,
  },
})

defineBlokkli({
  bundle: 'image_text',
})

const hasImage = computed(() => {
  return props.media && props.media.image ? 'is-half' : ''
})
</script>

<style lang="postcss" scoped>
.editor_image {
  max-height: 200px;
  max-width: 200px;
  padding-right: 20px;
}
</style>
