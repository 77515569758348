
    import { defineAsyncComponent } from '#imports'
    
import BlokkliComponent_accordion_index from '/home/portal/www/frontend/components/Paragraph/Accordion/index.vue'
import BlokkliComponent_frontpage_hero_index from '/home/portal/www/frontend/components/Paragraph/FrontpageHero/index.vue'
import BlokkliComponent_accordion_element_index from '/home/portal/www/frontend/components/Paragraph/Accordion/Element/index.vue'
import BlokkliComponent_membership_teaser_index from '/home/portal/www/frontend/components/Paragraph/MembershipTeaser/index.vue'
import BlokkliComponent_newsletter_index from '/home/portal/www/frontend/components/Paragraph/Newsletter/index.vue'
import BlokkliComponent_views_index from '/home/portal/www/frontend/components/Paragraph/Views/index.vue'
import BlokkliComponent_quote_index from '/home/portal/www/frontend/components/Paragraph/Quote/index.vue'
import BlokkliComponent_from_library_index from '/home/portal/www/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/FromLibrary/index.vue'
import BlokkliComponent_image_text_index from '/home/portal/www/frontend/components/Paragraph/ImageText/index.vue'
import BlokkliComponent_blokkli_fragment_index from '/home/portal/www/frontend/node_modules/blokkli-beta/dist/runtime/components/Blocks/Fragment/index.vue'

const global: Record<string, any> = {
  block_accordion: BlokkliComponent_accordion_index,
  block_frontpage_hero: BlokkliComponent_frontpage_hero_index,
  block_accordion_element: BlokkliComponent_accordion_element_index,
  block_membership_teaser: BlokkliComponent_membership_teaser_index,
  block_newsletter: BlokkliComponent_newsletter_index,
  block_views: BlokkliComponent_views_index,
  block_quote: BlokkliComponent_quote_index,
  block_from_library: BlokkliComponent_from_library_index,
  block_image_text: BlokkliComponent_image_text_index,
  block_blokkli_fragment: BlokkliComponent_blokkli_fragment_index
}

    
import BlokkliFragmentComponent_Test from '/home/portal/www/frontend/components/Paragraph/BlokkliFragment/FooBar/index.vue'

const globalFragments: Record<string, any> = {
  fragment_Test: BlokkliFragmentComponent_Test
}


const chunks: Record<string, () => Promise<any>> = {
  rare: () => import('#blokkli/chunk-rare')
}

const chunkMapping: Record<string, string> = {}

const fragmentChunkMapping: Record<string, string> = {}

export function getBlokkliItemComponent(bundle: string, fieldListType?: string, parentBundle?: string): any {
  const forFieldListType = 'block_' + bundle + '__field_list_type_' + fieldListType
  if (global[forFieldListType]) {
    return global[forFieldListType]
  }
  if (parentBundle) {
    const forParentBundle = 'block_' + bundle + '__parent_block_' + parentBundle
    if (global[forParentBundle]) {
      return global[forParentBundle]
    }
  }
  const key = 'block_' + bundle
  if (global[key]) {
    return global[key]
  }
  const chunkName = chunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}

export function getBlokkliFragmentComponent(name: string): any {
  const key = 'fragment_' + name
  if (globalFragments[key]) {
    return globalFragments[key]
  }
  const chunkName = fragmentChunkMapping[key]
  if (chunkName) {
    return defineAsyncComponent(() => chunks[chunkName]().then(chunk => {
      return chunk.default[key]
    }))
  }
}
